/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx } from "@emotion/core";

export const Footer = () => {
  return (
    <footer>
      <ul className="foot_link">
        <li>
          <a href="/terms.html">利用規約</a>
        </li>
        <li>
          <a href="/legal-notice.html">特定商取引法に基づく表記</a>
        </li>
        <li>
          <a href="/privacy.html">プライバシーポリシー</a>
        </li>
        <li>
          <a
            href="https://forms.gle/5PuvFbF88TM8jrcT9"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせ
          </a>
        </li>
      </ul>
      <p className="copy">
        © 2020 Clinic Management Consortium. All rights reserved.
      </p>
    </footer>
  );
};
