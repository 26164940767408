/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx } from "@emotion/core";

export const QuestionAnswer = () => {
  return (
    <section className="question">
      <h2>
        <img src={require("~image/qa.svg")} alt="QandA" />
        <span>よくある質問</span>
      </h2>
      <input id="qa_join" type="checkbox" defaultChecked={true} />
      <label htmlFor="qa_join">
        <h3>入会に関して</h3>
      </label>
      <dl className="question_list">
        <dt>入会申請しましたが、承認されません。</dt>
        <dd>
          ご入会申し込みをいただいてから承認までに最大10日ほどいただいております。10日以上経っても承認されない場合は、お手数ですが「
          <a
            href="https://forms.gle/5PuvFbF88TM8jrcT9"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせフォーム
          </a>
          」よりご連絡くださいませ。
        </dd>
        <dt>
          入会手続き中に「ページを閉じてしまった」「ブラウザが落ちてしまった」がどうすればいいですか？
        </dt>
        <dd>
          どのステップまで進まれていたかによってご案内が変わって参ります。
          <br />
          恐れ入りますが「
          <a
            href="https://forms.gle/5PuvFbF88TM8jrcT9"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせフォーム
          </a>
          」よりご連絡くださいませ。
        </dd>
        <dt>私は院長ですが、入会できますか？</dt>
        <dd>
          基本的にはクリニックの運営を院長と共に支える事務長、マネージャー、リーダーといったNo.2の方々を対象としたコミュニティとなっておりますので、院長先生のご入会はお断りさせていただいております。
          <br />
          ご事情がおありの場合は、「
          <a
            href="https://forms.gle/5PuvFbF88TM8jrcT9"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせフォーム
          </a>
          」より一度ご相談ください。
        </dd>
      </dl>
      <input id="qa_drop" type="checkbox" />
      <label htmlFor="qa_drop">
        <h3>退会に関して</h3>
      </label>
      <dl className="question_list">
        <dt>退会したいのですがどうすればいいですか？</dt>
        <dd>
          <a href="/unsubscribe.html" target="_blank" rel="noopener noreferrer">
            こちら
          </a>
          で解約(定期課金の停止)を進めてください。定期課金の停止を運営が確認後、メンバーからの退会処理を運営が行います。
          <blockquote>
            <h4>【Clinic Management Consortiumの退会について】</h4>
            <p>
              本コミュニティを退会をする場合には、毎月20日までに当会所定の方法でお手続きしてください。当該期日までにお手続きいただいた場合は、当月末をもって退会とします。
            </p>
            <p>
              本コミュニティは月単位でのお申込み、サービスの提供とさせていただいております。
            </p>
            <p>
              毎月20日までに退会の届出の受理ができない場合、退会は翌月末となりますのでご注意ください。
            </p>
          </blockquote>
        </dd>
        <dt>
          退会希望のため脱会しました。クレジットカード課金は終了しますか？
        </dt>
        <dd>
          コミュニティの退会は必ず所定の退会手続きをお願いします。ご自身で脱会しても退会にはならず、課金は停止されませんのでご注意くださいませ。
        </dd>
      </dl>
      <input id="qa_pay" type="checkbox" />
      <label htmlFor="qa_pay">
        <h3>お支払いに関して</h3>
      </label>
      <dl className="question_list">
        <dt>クレジットカードのみの支払いとなりますか？</dt>
        <dd>
          大変申し訳ございません。
          <br />
          お支払いにつきましてはクレジットカード決済のみにてお願いをしております。
        </dd>
        <dt>請求のタイミングについて教えてください。</dt>
        <dd>
          本コミュニティからカード会社へのご請求は当月１日の決済となります。
          （例：５月１日に、５月分の会費の決済となります）
          また、月途中でのご入会につきましては、入会月は日割り計算でのご請求となり、以降お申し出のない限り、毎月1日付で自動的にカード会社へ請求させていただきます。
        </dd>
        <dt>領収書の発行はお願いできますか？</dt>
        <dd>
          領収書の発行につきましては事務局にて対応を行っておらず、カード発行会社様からのご利用明細書をもってご対応をお願いしております。
          <br />
          ご希望に添えず恐縮ですが、ご確認ください。
        </dd>
        <dt>決済が失敗した理由はわかりますか？</dt>
        <dd>
          決済代行会社からは「決済に失敗した」ということしか情報が来ませんので、お客様ご自身でクレジットカード会社にお問い合わせください。
          <br />
          多くの場合は、有効期限切れ（更新をしていない）と限度額超過などです。
        </dd>
      </dl>
      <input id="qa_other" type="checkbox" />
      <label htmlFor="qa_other">
        <h3>各種お手続き・その他</h3>
      </label>
      <dl className="question_list">
        <dt>
          オンラインコミュニティの中で積極的に発言したりしたくありません。情報を得るだけでもよいのでしょうか？
        </dt>
        <dd>
          はい。積極的に交流する人も、まったく交流はせず情報を得て楽しまれる方も大歓迎です。
          <br />
          気が向いた時にはコミュニティ内で交流にトライしてみるのも良いかもしれません。
        </dd>
        <dt>
          何かサロンの中でお役に立ちたいのですが、そのような場はありますか？
        </dt>
        <dd>
          「医療現場対談」として、不定期的に配信する動画の参加者を募集しております。
          <br />
          今後、分科会などが発足する段階になりましたら、サポーター等の募集をすることはあるかもしれませんが、現時点では上記対談者の募集のみとなります。
        </dd>
        <dt>
          入会申込みしたメールアドレスと、異なるメールアドレスで、サロンに参加したいのですが、アカウント変更はできますか？
        </dt>
        <dd>
          システム上、アカウントの付け替えのようなお手続きはできません。
          <br />
          ご希望に添えず恐縮ではございますが、参加するアカウントのご変更をご希望の場合には、現在のアカウントにて退会いただいた後、ご希望のアカウントにて入会をお願いしております。
        </dd>
      </dl>
    </section>
  );
};
