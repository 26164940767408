import React from "react";
import { render } from "react-dom";
import { QuestionAnswer } from "~components/QuestionAnswer";
import { Footer } from "~components/Footer";

const $questionAnswer = document.getElementById("question-and-answer")!;
const $footer = document.getElementById("footer")!;
function renderApp() {
  render(<QuestionAnswer />, $questionAnswer);
  render(<Footer />, $footer);
}

renderApp();

if (module.hot) {
  module.hot.accept(renderApp);
}
